<template>
<v-container>
    <h1 class="pa-5">Projects</h1>
    <v-data-table :headers="headers" :items="projects" class="elevation-1" @click:row="goToProject" hide-default-footer :items-per-page="projects.length">
    </v-data-table>
</v-container>
</template>

<script>
export default {
    data: () => ({
        projects: [],
        headers: [{
            text: 'Project Name',
            align: 'start',
            value: 'Name'
        }]
    }),
    created() {
        fetch('https://sparkprize.azurewebsites.net/api/v1/project/?apikey=' + this.$apiKey)
            .then(response => response.json())
            .then(data => {
                this.projects = data;
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });
    },
    methods: {
        goToProject(item) {
            localStorage.setItem('project', JSON.stringify({
                project: item.Name,
                intent: item.Intent,
                whaMessage: item.WhaMessage,
            }));
            this.$router.push({
                name: 'project',
                params: {
                    id: item.Id
                }
            });
        }
    }
}
</script>

<style>
.v-data-table {
    cursor: pointer;
}

.v-chip {
    background-color: #1976d2;
    color: white;
}
</style>
